import _ from 'underscore';

angular
  .module('public')
  .factory('DataService', function () {
    const data = window.DATA || {}
    return {
      ...data,
      gym_name: _.unescape(data.gym_name) || '',
      gym_email: _.unescape(data.gym_email) || '',
      gym_phone_number: _.unescape(data.gym_phone_number) || '',
      gym_address: _.unescape(data.gym_address) || ''
    };
  });
