export const containsNumber = (password) => {
  if (!password || !password.trim()) return false;
  return new RegExp('(?=.*[0-9])').test(password);
};

export const containsUpperCaseLetter = (password) => {
  if (!password || !password.trim()) return false;
  return new RegExp('(?=.*[A-Z])').test(password);
};

export const containsLowerCaseLetter = (password) => {
  if (!password || !password.trim()) return false;
  return new RegExp('(?=.*[a-z])').test(password);
};

export const hasValidLength = (password) => {
  if (!password || !password.trim()) return false;
  return password.trim().length >= 10;
};

export const getPasswordError = (password) => {
  if (!password) return 'This field is requred';

  if (!hasValidLength(password)) return 'Must be 10 chars or longer';

  if (!containsNumber(password)) {
    return 'Must contain at least one number';
  }

  if (!containsUpperCaseLetter(password)) {
    return 'Must contain at least one upper case letter';
  }
  if (!containsLowerCaseLetter(password)) {
    return 'Must contain at least one lower case letter';
  }
};

const getPasswordConfirmationError = (password, confirmation) => {
  if (!confirmation) return 'This field is requred';
  if (password !== confirmation) return 'Passwords do not match';
};

const passwordValidator = (password, confirmation) => {
  const p = password ? password.trim() : '';
  const c = confirmation ? confirmation.trim() : '';

  const passwordError = getPasswordError(p);
  const confirmationError = getPasswordConfirmationError(p, c);

  if (!passwordError && !confirmationError) return undefined;

  return {
    password: passwordError ? [passwordError] : undefined,
    password_confirmation: confirmationError ? [confirmationError] : undefined,
  };
};

export default passwordValidator;
