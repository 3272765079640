import 'jquery';
import 'jquery-ujs';
import 'angular';
import 'angular-ui-router';
import 'ng-rollbar';
import 'angular-recaptcha';
import 'ngreact';

const requireAll = (requireContext) => {
  requireContext.keys().map((item) => requireContext(item));
};

requireAll(require.context('modules/public', true));
import 'modules/on_demand/_shared.js';
import 'react/entry_points/shared/password_strength.tsx';
import 'modules/shared/icons/icon_info.js.coffee';
import 'modules/shared/icons/icon_back.js.coffee';
import 'modules/shared/icons/icon_check_circle_inverse.js.coffee';
