angular
  .module 'public'
  .controller 'SignInCtrl', (
    $scope,
  ) ->
    $scope.params = {}
    $scope.data = {}

    $scope.togglePassword = ->
      $scope.params.passwordVisible = !$scope.params.passwordVisible
