import {containsNumber, containsUpperCaseLetter, containsLowerCaseLetter, hasValidLength} from 'helpers/password_validator'

angular
  .module 'public'
  .controller 'InvitationCtrl', (
    $scope,
    $stateParams,
    $http
  ) ->
    $http.get("/mobile_users/invitations/#{$stateParams.hash}").then (response) ->
      $scope.invitation = response.data

    $scope.data = {}

    getError = ->
      switch
        when $scope.data.password isnt $scope.data.passwordConfirmation
          'Passwords do not match'
        else
          undefined

    $scope.containsNumber = -> containsNumber($scope.data.password)
    $scope.containsUpperCaseLetter = -> containsUpperCaseLetter($scope.data.password)
    $scope.containsLowerCaseLetter = -> containsLowerCaseLetter($scope.data.password)
    $scope.hasValidLength = -> hasValidLength($scope.data.password)

    $scope.submit = ->
      $scope.error = getError()
      return if $scope.error

      $scope.isLoading = true
      params =
        cs: $stateParams.cs
        invitation:
          password: $scope.data.password
          password_confirmation: $scope.data.passwordConfirmation
      $http.put("/mobile_users/invitations/#{$stateParams.hash}", params).then (response) ->
        $scope.isLoading = false
        window.location.href = response.data.redirect_url
      , (response) ->
        $scope.isLoading = false
        error = response.data.errors.password?[0]
        if error
          $scope.error = "Password #{error}"

    $scope.requestInvitation = ->
      $scope.isLoading = true
      url = "/mobile_users/invitations/#{$stateParams.hash}/request_new_invite?gid=#{$stateParams.gid}"
      $http.put(url).then () ->
        $scope.isLoading = false
        $scope.invitationRequested = true
      , (response) ->
        $scope.isLoading = false
