import {containsNumber, containsUpperCaseLetter, containsLowerCaseLetter, hasValidLength} from 'helpers/password_validator'

angular
  .module 'public'
  .controller 'PasswordResetCtrl', (
    $scope,
  ) ->
    $scope.data = {}

    $scope.containsNumber = -> containsNumber($scope.data.password)
    $scope.containsUpperCaseLetter = -> containsUpperCaseLetter($scope.data.password)
    $scope.containsLowerCaseLetter = -> containsLowerCaseLetter($scope.data.password)
    $scope.hasValidLength = -> hasValidLength($scope.data.password)
