import emailValidator from 'helpers/email_validator'
import { containsNumber, containsUpperCaseLetter, containsLowerCaseLetter, hasValidLength } from 'helpers/password_validator'

angular
  .module 'public'
  .controller 'SignUpCtrl', (
    $scope,
    vcRecaptchaService,
  ) ->
    $scope.params = {}
    $scope.data =
      first_name: angular.element('#user_first_name')[0]?.value
      last_name: angular.element('#user_last_name')[0]?.value
      email: angular.element('#user_email')[0]?.value
    $scope.errors = {}
    $scope.blurEvents = {}
    $scope.recaptchaKey = window.DATA?.recaptchaKey

    $scope.containsNumber = -> containsNumber($scope.data.password)
    $scope.containsUpperCaseLetter = -> containsUpperCaseLetter($scope.data.password)
    $scope.containsLowerCaseLetter = -> containsLowerCaseLetter($scope.data.password)
    $scope.hasValidLength = -> hasValidLength($scope.data.password)

    $scope.enablePasswordValidation = ->
      $scope.params.passwordRequired = true

    $scope.onRecaptchaSubmitted = (response) ->
      $scope.recaptchaToken = response
      document.getElementById('submit-button').click()

    $scope.onRecaptchaCreated = (id) ->
      $scope.recaptchaWidgetId = id

    $scope.togglePassword = ->
      $scope.params.passwordVisible = !$scope.params.passwordVisible

    validate = (field, form) ->
      value = if field is 'email' && form
        form['user[email]'].$viewValue
      else
        $scope.data[field] || ''

      if !value || !value.trim()
        $scope.errors[field] = ['This field is required']
        return
      if field is 'email' && !emailValidator(value)
        $scope.errors[field] = ['This field is invalid']
        return

      $scope.errors[field] = undefined

    validateWithBlur = (field, form) ->
      $scope.blurEvents[field] = true
      validate(field, form)

    $scope.onFieldChange = (field, form) ->
      if $scope.blurEvents[field]
        validate(field, form)

    $scope.onFieldBlur = (field) ->
      $scope.blurEvents[field] = true

    $scope.onFieldFocus = (field) ->
      if field is 'password'
        $scope.passwordFieldFocused = true

    getRecaptchaToken = ->
      return if $scope.recaptchaLoading
      $scope.recaptchaLoading = true
      vcRecaptchaService.execute($scope.recaptchaWidgetId)

    $scope.back = ->
      window.history.back()

    $scope.onFormSubmit = (event) ->
      validateWithBlur('first_name')
      validateWithBlur('last_name')
      validateWithBlur('email')
      if $scope.params.passwordRequired
        validateWithBlur('password')
      if _.compact(_.values($scope.errors)).length
        event.preventDefault()
        return

      if $scope.recaptchaKey && !$scope.recaptchaToken
        getRecaptchaToken()
        event.preventDefault()
