angular.module 'public', [
  'tandibar/ng-rollbar',
  'vcRecaptcha'
  'ui.router',
  'shared',
  'react'
]

  .config (
    $locationProvider,
    $stateProvider,
    $httpProvider,
    RollbarProvider
  ) ->
    meta = angular.element(document.querySelectorAll('meta[name=csrf-token]'))
    $httpProvider.defaults.xsrfHeaderName = 'X-CSRF-Token'
    $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
    $locationProvider.html5Mode(true)
    RollbarProvider.init window._rollbarConfig
    $stateProvider
      .state 'mobileUsers',
        url: '/mobile_users',
        abstract: true,
        template: '<ui-view>'

      .state 'mobileUsers.invitation',
        url: '/invitations/:hash?gid&cs'
        controller: 'InvitationCtrl'
        template: require('templates/public/mobile_users/invitation.html.slim')
